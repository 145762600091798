import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={process.env.PUBLIC_URL + "anima_aboe_logo.png"}
          alt="Anima Above Logo"
          style={{ width: "50%", height: "50%" }}
        />
        <p>Under maintenance</p>
      </header>
    </div>
  );
}

export default App;
